import React from 'react'
import Footer from '../components/Footer'
import { Nav } from '../components/Navbar'
import Transition from '../Transition'

function AZSliding() {
  return (
    <Transition>
        <div className="">
            
          <Nav />
    
          <section className="flex flex-col justify-center items-center relative h-[50vh] shadow-lg overflow-hidden">
            <div className="z-10 ">
                <h2 className="text-center text-white font-bold uppercase">Showers</h2>
                <h3 className="text-6xl uppercase text-white font-bold text-center drop-shadow-lg filter">AZ-Sliding</h3>
                <p className="text-white text-center lg:w-96 mx-auto">
                  Frameless Sliding Doors! Compact!
                </p>
            </div>

            <img src="https://i.imgur.com/eTMSUZA.jpg" className="select-none w-full h-full object-cover brightness-[.4] absolute top-0 left-0" />
          </section> 

          <section className="lg:w-[80vw] w-full mx-auto my-10">
            <p className="text-center mx-auto lg:w-3/4">
            Sliding shower doors are a great choice for homeowners who want a shower enclosure that is easy to use and maintain. These doors are available in a variety of styles and materials, making them suitable for any bathroom design. Sliding shower doors are also space-saving solutions as they do not require extra space for the door to swing open. <br/><br/>They are also easy to clean and maintain, as they are comprised of sealed glass and tracks that can be wiped down with simple cleaning solutions. Additionally, sliding shower doors provide a beautiful accent to any bathroom design, making them a great choice for anyone looking to update their bathroom.
            </p>
          </section>

          <section className="grid lg:grid-cols-2 justify-start items-start relative">
            <div className="relative flex-1 ">
              <div className="w-full top-0">
                <img className="w-full lg:h-screen object-cover" src="https://i.imgur.com/eTMSUZA.jpg" />
              </div>
              <div className="w-full top-0">
                <img className="w-full lg:h-screen object-cover" src="https://i.imgur.com/chGSptc.jpg" />
              </div>
              <div className="w-full top-0">
                <img className="w-full lg:h-screen object-cover" src="https://i.imgur.com/q9cRjNf.jpg" />
              </div>
            </div>
            <div className="flex-1 relative top-0 h-full lg:mt-0 mt-4">
              <div className="lg:p-10 flex h-full relative top-0 flex-col justify-start items-center w-full">
                <div className="w-3/4 lg:sticky top-[7rem]">
                  <h4 className="text-blue-600 font-bold text-3xl">DS01 - Frameless Double Sliding</h4>
                  <p className="mt-2 text-sm">The Frameless Double Sliding Shower Door is a stylish and modern addition to any bathroom. This shower door is designed with a sleek, frameless look to give your bathroom a more open feel. <br/> <br/>The double sliding door design helps to maximize space and makes it easy to enter and exit the shower without having to worry about a large, bulky door. The door is constructed of high-grade aluminum and tempered glass for durability and long-lasting use.</p>
                
                  {/* <div className="flex mt-4 gap-4 flex-col">
                    
                    <div className="flex justify-center items-center flex-row">
                        <img className="flex-1 lg:w-1/2 lg:mr-4" src="https://i.imgur.com/lDYdDBP.jpg" />
                        <div className="flex-1">
                            <h3 className="text-lg font-bold lg:text-right">Quality Hardware</h3>
                            <p className="lg:text-right">
                                Certified 304 Stainless Steel. Be at rest that you will not have any rusting.
                            </p>
                        </div>
                    </div>

                    <div className="flex justify-center items-center flex-row">
                        <div className="flex-1">
                            <h3 className="text-lg font-bold">Bottom Guide Rail</h3>
                            <p>
                                Water Leakage: 0
                            </p>
                        </div>
                        <img className="flex-1 lg:w-1/2 lg:ml-4" src="https://i.imgur.com/bkGJk6q.jpg" />
                    </div>

                  </div> */}
                  <div className="mt-4">
                    <ul>
                        <li><span className="font-semibold">Glass Width: </span>3/8" ( 10mm )</li>
                        <li><span className="font-semibold">Finishes: </span><span>Matte Black, </span><span>Brushed Gold, </span><span>Brushed Nickel & </span><span>Chrome</span></li>
                    </ul>
                    <div class="py-4 flex lg:flex-row flex-col items-start justify-start gap-4">
                        <div class="overflow-x-auto">
                            <h3>Shower Doors</h3>
                            <table class="table-auto w-auto border-collapse border shadow-lg">
                                <thead class="text-xs font-semibold uppercase text-gray-600 bg-blue-200">
                                    <tr>
                                        <th class="py-2 px-4 whitespace-nowrap">
                                            <div class="font-semibold text-left">Width</div>
                                        </th>
                                        <th class="py-2 px-4 whitespace-nowrap">
                                            <div class="font-semibold text-left">Height</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="text-sm divide-y divide-gray-100">
                                    <tr>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">48"</div>
                                        </td>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">76"</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">54"</div>
                                        </td>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">76"</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">60"</div>
                                        </td>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">76"</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">66"</div>
                                        </td>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">76"</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">72"</div>
                                        </td>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">76"</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">76"</div>
                                        </td>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">76"</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="overflow-x-auto">
                            <h3>Shower Bathtub</h3>
                            <table class="table-auto w-auto border-collapse border shadow-lg">
                                <thead class="text-xs font-semibold uppercase text-gray-600 bg-blue-200">
                                    <tr>
                                        <th class="py-2 px-4 whitespace-nowrap">
                                            <div class="font-semibold text-left">Width</div>
                                        </th>
                                        <th class="py-2 px-4 whitespace-nowrap">
                                            <div class="font-semibold text-left">Height</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="text-sm divide-y divide-gray-100">
                                    <tr>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">60"</div>
                                        </td>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">66"</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">60"</div>
                                        </td>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">72"</div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
    
                  </div>
                </div>

              </div>
            </div>
          </section>

          <section className="grid lg:grid-cols-2 justify-start items-start relative">
            <div className="flex-1 relative top-0 h-full lg:mt-0 mt-4 lg:order-1 order-2">
              <div className="lg:p-10 flex h-full relative top-0 flex-col justify-start items-center w-full ">
                <div className="w-3/4 lg:sticky top-[7rem]">
                  <h4 className="text-blue-600 font-bold text-3xl">SS04 - Rounded Frameless Single Sliding</h4>
                  <p className="mt-2 text-sm">The SS04 Model is perfect and one of our best selling shower doors. With it's rounded-style like design. It gives a pretty good archaic medieval vibe.<br/> <br/>With it's Ladder-Back type of handle, you can ensure a slick and modern design added to any of your bathrooms.</p>
                
                  {/* <div className="flex mt-4 gap-4 flex-col">
                    
                    <div className="flex justify-center items-center flex-row">
                        <img className="flex-1 lg:w-1/2 lg:mr-4" src="https://i.imgur.com/EIryu6O.jpg" />
                        <div className="flex-1">
                            <h3 className="text-lg font-bold lg:text-right">Quality Hardware</h3>
                            <p className="lg:text-right">
                                Certified 304 Stainless Steel. Be at rest that you will not have any rusting.
                            </p>
                        </div>
                    </div>

                    <div className="flex justify-center items-center flex-row">
                        <div className="flex-1">
                            <h3 className="text-lg font-bold">Bottom Guide Rail</h3>
                            <p>
                                Water Leakage: 0
                            </p>
                        </div>
                        <img className="flex-1 lg:w-1/2 lg:ml-4" src="https://i.imgur.com/V2T4bFZ.jpg" />
                    </div>

                  </div> */}
                  <div className="mt-4">
                    <ul>
                        <li><span className="font-semibold">Glass Width: </span>3/8" ( 10mm )</li>
                        <li><span className="font-semibold">Finishes: </span><span>Matte Black, </span><span>Brushed Gold, </span><span>Brushed Nickel & </span><span>Chrome</span></li>
                    </ul>
                    <div class="py-4 flex lg:flex-row flex-col items-start justify-start gap-4">
                        <div class="overflow-x-auto">
                            <h3>Shower Doors</h3>
                            <table class="table-auto w-auto border-collapse border shadow-lg">
                                <thead class="text-xs font-semibold uppercase text-gray-600 bg-blue-200">
                                    <tr>
                                        <th class="py-2 px-4 whitespace-nowrap">
                                            <div class="font-semibold text-left">Width</div>
                                        </th>
                                        <th class="py-2 px-4 whitespace-nowrap">
                                            <div class="font-semibold text-left">Height</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="text-sm divide-y divide-gray-100">
                                    <tr>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">48"</div>
                                        </td>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">76"</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">54"</div>
                                        </td>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">76"</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">60"</div>
                                        </td>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">76"</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">66"</div>
                                        </td>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">76"</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">72"</div>
                                        </td>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">76"</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">76"</div>
                                        </td>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">76"</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="overflow-x-auto">
                            <h3>Shower Bathtub</h3>
                            <table class="table-auto w-auto border-collapse border shadow-lg">
                                <thead class="text-xs font-semibold uppercase text-gray-600 bg-blue-200">
                                    <tr>
                                        <th class="py-2 px-4 whitespace-nowrap">
                                            <div class="font-semibold text-left">Width</div>
                                        </th>
                                        <th class="py-2 px-4 whitespace-nowrap">
                                            <div class="font-semibold text-left">Height</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="text-sm divide-y divide-gray-100">
                                    <tr>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">60"</div>
                                        </td>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">66"</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">60"</div>
                                        </td>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">72"</div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
    
                  </div>
                </div>

              </div>
            </div>
            <div className="relative flex-1 lg:order-2 order-1">
              <div className="w-full top-0">
                <img className="w-full lg:h-screen object-cover" src="https://i.imgur.com/YXMuTmY.jpg" />
              </div>
              <div className="w-full top-0">
                <img className="w-full lg:h-screen object-cover" src="https://i.imgur.com/uq6j2FE.jpg" />
              </div>
              <div className="w-full top-0">
                <img className="w-full lg:h-screen object-cover" src="https://i.imgur.com/CXZSlt5.jpg" />
              </div>
            </div>
          </section>

          <section className="grid lg:grid-cols-2 justify-start items-start relative">
            <div className="relative flex-1 ">
              <div className="w-full top-0">
                <img className="w-full lg:h-screen object-cover" src="https://i.imgur.com/FwogaD3.jpg" />
              </div>
              <div className="w-full top-0">
                <img className="w-full lg:h-screen object-cover" src="https://i.imgur.com/CbK05ng.jpg" />
              </div>
              <div className="w-full top-0">
                <img className="w-full lg:h-screen object-cover" src="https://i.imgur.com/4nh4SYt.jpg" />
              </div>
            </div>
            <div className="flex-1 relative top-0 h-full lg:mt-0 mt-4">
              <div className="lg:p-10 flex h-full relative top-0 flex-col justify-start items-center w-full">
                <div className="w-3/4 lg:sticky top-[7rem]">
                  <h4 className="text-blue-600 font-bold text-3xl">SS05 - Squared Frameless Single Sliding</h4>
                  <p className="mt-2 text-sm">The SS05 is an elegant, modern shower door that adds a touch of luxury to any bathroom. This frameless shower door features a sliding glass panel and a sleek, frameless design that enhances the look of any shower enclosure. <br/> <br/>The single sliding panel allows for easy access to the shower and the tempered glass is both durable and attractive. The door is available in a variety of sizes and finishes, so you can find the perfect fit for your bathroom. The SS05 also features a stainless steel handle and an adjustable bottom guide for a secure, water-tight closure. With its sophisticated design and quality construction, this frameless sliding shower door is sure to add the perfect finishing touch to any bathroom.</p>
                
                  {/* <div className="flex mt-4 gap-4 flex-col">
                    
                    <div className="flex justify-center items-center flex-row">
                        <img className="flex-1 lg:w-1/2 lg:mr-4" src="https://i.imgur.com/CQyU1pq.jpg" />
                        <div className="flex-1">
                            <h3 className="text-lg font-bold lg:text-right">Quality Hardware</h3>
                            <p className="lg:text-right">
                                Certified 304 Stainless Steel. Be at rest that you will not have any rusting.
                            </p>
                        </div>
                    </div>

                    <div className="flex justify-center items-center flex-row">
                        <div className="flex-1">
                            <h3 className="text-lg font-bold">Bottom Guide Rail</h3>
                            <p>
                                Water Leakage: 0
                            </p>
                        </div>
                        <img className="flex-1 lg:w-1/2 lg:ml-4" src="https://i.imgur.com/wkjNiFI.jpg" />
                    </div>

                  </div> */}

<div className="mt-4">
                    <ul>
                        <li><span className="font-semibold">Glass Width: </span>3/8" ( 10mm )</li>
                        <li><span className="font-semibold">Finishes: </span><span>Matte Black, </span><span>Brushed Gold, </span><span>Brushed Nickel & </span><span>Chrome</span></li>
                    </ul>
                    <div class="py-4 flex lg:flex-row flex-col items-start justify-start gap-4">
                        <div class="overflow-x-auto">
                            <h3>Shower Doors</h3>
                            <table class="table-auto w-auto border-collapse border shadow-lg">
                                <thead class="text-xs font-semibold uppercase text-gray-600 bg-blue-200">
                                    <tr>
                                        <th class="py-2 px-4 whitespace-nowrap">
                                            <div class="font-semibold text-left">Width</div>
                                        </th>
                                        <th class="py-2 px-4 whitespace-nowrap">
                                            <div class="font-semibold text-left">Height</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="text-sm divide-y divide-gray-100">
                                    <tr>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">48"</div>
                                        </td>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">76"</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">54"</div>
                                        </td>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">76"</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">60"</div>
                                        </td>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">76"</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">66"</div>
                                        </td>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">76"</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">72"</div>
                                        </td>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">76"</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">76"</div>
                                        </td>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">76"</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="overflow-x-auto">
                            <h3>Shower Bathtub</h3>
                            <table class="table-auto w-auto border-collapse border shadow-lg">
                                <thead class="text-xs font-semibold uppercase text-gray-600 bg-blue-200">
                                    <tr>
                                        <th class="py-2 px-4 whitespace-nowrap">
                                            <div class="font-semibold text-left">Width</div>
                                        </th>
                                        <th class="py-2 px-4 whitespace-nowrap">
                                            <div class="font-semibold text-left">Height</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="text-sm divide-y divide-gray-100">
                                    <tr>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">60"</div>
                                        </td>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">66"</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">60"</div>
                                        </td>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">72"</div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
    
                  </div>
                </div>

              </div>
            </div>
          </section>

          <section className="grid lg:grid-cols-2 justify-start items-start relative">
            <div className="flex-1 relative top-0 h-full lg:mt-0 mt-4 lg:order-1 order-2">
              <div className="lg:p-10 flex h-full relative top-0 flex-col justify-start items-center w-full">
                <div className="w-3/4 lg:sticky top-[7rem]">
                  <h4 className="text-blue-600 font-bold text-3xl">DS06 - Framed Frameless Double Sliding</h4>
                  <p className="mt-2 text-sm">The DS06 Model is perfect and one of our best selling shower doors. With it's 5/16" Glass Width, it's perfect for single-house apartments.<br/> <br/> Framed style you can rely on for safety, make sure nothing happens or water leakage.</p>
                
                  {/* <div className="flex mt-4 gap-4 flex-col">
                    
                    <div className="flex justify-center items-center flex-row">
                        <img className="flex-1 lg:w-1/2 lg:mr-4" src="https://i.imgur.com/EIryu6O.jpg" />
                        <div className="flex-1">
                            <h3 className="text-lg font-bold lg:text-right">Quality Hardware</h3>
                            <p className="lg:text-right">
                                Certified 304 Stainless Steel. Be at rest that you will not have any rusting.
                            </p>
                        </div>
                    </div>

                    <div className="flex justify-center items-center flex-row">
                        <div className="flex-1">
                            <h3 className="text-lg font-bold">Bottom Guide Rail</h3>
                            <p>
                                Water Leakage: 0
                            </p>
                        </div>
                        <img className="flex-1 lg:w-1/2 lg:ml-4" src="https://i.imgur.com/V2T4bFZ.jpg" />
                    </div>

                  </div> */}

<div className="mt-4">
                    <ul>
                        <li><span className="font-semibold">Glass Width: </span>3/8" ( 10mm )</li>
                        <li><span className="font-semibold">Finishes: </span><span>Matte Black, </span><span>Brushed Gold, </span><span>Brushed Nickel & </span><span>Chrome</span></li>
                    </ul>
                    <div class="py-4 flex lg:flex-row flex-col items-start justify-start gap-4">
                        <div class="overflow-x-auto">
                            <h3>Shower Doors</h3>
                            <table class="table-auto w-auto border-collapse border shadow-lg">
                                <thead class="text-xs font-semibold uppercase text-gray-600 bg-blue-200">
                                    <tr>
                                        <th class="py-2 px-4 whitespace-nowrap">
                                            <div class="font-semibold text-left">Width</div>
                                        </th>
                                        <th class="py-2 px-4 whitespace-nowrap">
                                            <div class="font-semibold text-left">Height</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="text-sm divide-y divide-gray-100">
                                    <tr>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">60"</div>
                                        </td>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">75"</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="overflow-x-auto">
                            <h3>Shower Bathtub</h3>
                            <table class="table-auto w-auto border-collapse border shadow-lg">
                                <thead class="text-xs font-semibold uppercase text-gray-600 bg-blue-200">
                                    <tr>
                                        <th class="py-2 px-4 whitespace-nowrap">
                                            <div class="font-semibold text-left">Width</div>
                                        </th>
                                        <th class="py-2 px-4 whitespace-nowrap">
                                            <div class="font-semibold text-left">Height</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="text-sm divide-y divide-gray-100">
                                    <tr>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">60"</div>
                                        </td>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">60"</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">60"</div>
                                        </td>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">66"</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">60"</div>
                                        </td>
                                        <td class="py-2 px-4 whitespace-nowrap">
                                            <div class="text-left">72"</div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
    
                  </div>
                </div>

              </div>
            </div>
            <div className="relative flex-1 lg:order-2 order-1">
              <div className="w-full top-0">
                <img className="w-full lg:h-screen object-cover" src="https://i.imgur.com/Z91DEkn.jpg" />
              </div>
              <div className="w-full top-0">
                <img className="w-full lg:h-screen object-cover" src="https://i.imgur.com/XNxyoXM.jpg" />
              </div>
              <div className="w-full top-0">
                <img className="w-full lg:h-screen object-cover" src="https://i.imgur.com/8i7UzFT.jpg" />
              </div>
            </div>
          </section>

          <div className="mt-10">
            <Footer />
          </div>

        </div>
    </Transition>
  )
}

export default AZSliding