import React from 'react'
import Footer from '../components/Footer'
import { Nav } from '../components/Navbar'
import Transition from '../Transition'

function AZSwing() {
  return (
    <Transition>
        <div className="">
            
          <Nav />
    
          <section className="flex flex-col justify-center items-center relative h-[50vh] shadow-lg overflow-hidden">
            <div className="z-10 ">
                <h2 className="text-center text-white font-bold uppercase">Showers</h2>
                <h3 className="text-6xl uppercase text-white font-bold text-center drop-shadow-lg filter">AZ-Swing</h3>
                <p className="text-white text-center lg:w-96 mx-auto">
                  Frameless Swing Doors! Luxurious!
                </p>
            </div>

            <img src="https://i.imgur.com/XKn3Y5t.jpg" className="select-none w-full h-full object-cover brightness-[.4] absolute top-0 left-0" />
          </section> 

          <section className="lg:w-[80vw] w-full mx-auto my-10">
            <p className="text-center mx-auto lg:w-3/4">
            Swing shower doors are the best option for showers because they provide a more open and spacious feel than traditional sliding doors. They are also easier to open and close, as they are designed to swing outward or inward as needed. Swing doors are also less likely to get scratched or damaged, as they don’t have to slide across a track. <br/><br/>As an added bonus, swing doors come in a variety of styles and finishes, so you can find the perfect fit for your bathroom. With swing doors, you can have the perfect combination of convenience and style.
            </p>
          </section>

          <section className="grid lg:grid-cols-2 justify-start items-start relative">
            <div className="relative flex-1 ">
              <div className="w-full top-0">
                <img className="w-full h-screen object-cover" src="https://i.imgur.com/YLnkNzH.jpg" />
              </div>
              <div className="w-full top-0">
                <img className="w-full h-screen object-cover" src="https://i.imgur.com/ZhykUeF.jpg" />
              </div>
            </div>
            <div className="flex-1 relative top-0 h-full lg:mt-0 mt-4">
              <div className="lg:p-10 flex h-full relative top-0 flex-col justify-start items-center w-full">
                <div className="w-3/4 lg:sticky top-[7rem]">
                  <h4 className="text-blue-600 font-bold text-3xl">Spacious Feeling</h4>
                  <p className="mt-2 text-sm">Swing shower doors create a spacious feeling in bathrooms because they open outward or inward, depending on the design, which allows more room to move in the shower area.  <br/> <br/>Furthermore, the door can be designed to be wider than a regular sliding door, which adds to the feeling of spaciousness. Additionally, swing shower doors give the option to have a frameless design, which creates an even more open feel.</p>
                  <img src="https://i.imgur.com/LJx9Ftp.png" />
                </div>

              </div>
            </div>
          </section>

          <section className="grid lg:grid-cols-2 justify-start items-start relative">
            <div className="flex-1 relative top-0 h-full lg:mt-0 mt-4">
              <div className="lg:p-10 flex h-full relative top-0 flex-col justify-start items-center w-full">
                <div className="w-3/4 lg:sticky top-[7rem]">
                  <h4 className="text-blue-600 font-bold text-3xl">Frameless or Framed</h4>
                  <p className="mt-2 text-sm">With Frameless you can experience the inside of your shower to it's maximum, you can now see clearly and add this shower to your bathroom to enhance and level up! With minimum low-profile hardware.<br/> <br/>With the framed style though, it will have channels over the glass and make sure the ends of the glass has a view.</p>
                
                  {/* <div className="flex mt-4 gap-4 flex-col">
                    
                    <div className="flex justify-center items-center flex-row">
                        <img className="flex-1 lg:w-1/2 lg:mr-4" src="https://i.imgur.com/EIryu6O.jpg" />
                        <div className="flex-1">
                            <h3 className="text-lg font-bold lg:text-right">Quality Hardware</h3>
                            <p className="lg:text-right">
                                Certified 304 Stainless Steel. Be at rest that you will not have any rusting.
                            </p>
                        </div>
                    </div>

                    <div className="flex justify-center items-center flex-row">
                        <div className="flex-1">
                            <h3 className="text-lg font-bold">Bottom Guide Rail</h3>
                            <p>
                                Water Leakage: 0
                            </p>
                        </div>
                        <img className="flex-1 lg:w-1/2 lg:ml-4" src="https://i.imgur.com/V2T4bFZ.jpg" />
                    </div>

                  </div> */}
                  <div className="mt-4">
                    <ul>
                        <li><span className="font-semibold">Glass Width: </span>3/8" ( 10mm )</li>
                        <li><span className="font-semibold">Finishes: </span><span>Matte Black, </span><span>Brushed Gold, </span><span>Brushed Nickel & </span><span>Chrome</span></li>
                    </ul>

                    <img src="https://i.imgur.com/2kM5BVC.png" />
    
                  </div>
                </div>

              </div>
            </div>
            <div className="relative flex-1 ">
              <div className="w-full top-0">
                <img className="w-full h-screen object-cover" src="https://i.imgur.com/Yb1dvVm.jpg" />
              </div>
              <div className="w-full top-0">
                <img className="w-full h-screen object-cover" src="https://myshowerdoor.com/wp-content/uploads/2022/02/swinging-door-panel-matte-black-002.jpg" />
              </div>
            </div>
          </section>

          <section className="grid lg:grid-cols-2 justify-start items-start relative">
            <div className="relative flex-1 ">
              <div className="w-full top-0">
                <img className="w-full h-screen object-cover" src="https://i.imgur.com/NO3eq2e.jpg" />
              </div>
              <div className="w-full top-0">
                <img className="w-full h-screen object-cover" src="https://materialbank-cdn.global.ssl.fastly.net/media/catalog/product/base_image/10000/100357263.jpg?width=800" />
              </div>
            </div>
            <div className="flex-1 relative top-0 h-full lg:mt-0 mt-4">
              <div className="lg:p-10 flex h-full relative top-0 flex-col justify-start items-center w-full">
                <div className="w-3/4 lg:sticky top-[7rem]">
                  <h4 className="text-blue-600 font-bold text-3xl">Accessories</h4>
                  <p className="mt-2 text-sm">There is multiple accessories that can be done for your shower. For instance:</p>

                <div className="mt-4">
                    <ul>
                        <li><span className="font-semibold">Glass Width: </span>3/8" ( 10mm ) - 1/2" ( 12mm )</li>
                        <li><span className="font-semibold">Glass Type: </span>Clear - UltraClear - Acid Etched - Frosted - Raining</li>
                        <li><span className="font-semibold">Finishes: </span><span>Matte Black, </span><span>Brushed Gold, </span><span>Brushed Nickel & </span><span>Chrome</span></li>
                        <li><span className="font-semibold">Style: </span>Framed - Frameless</li>
                    </ul>

                    <img src="https://i.imgur.com/AkmSNyX.png" />
    
                  </div>
                </div>

              </div>
            </div>
          </section>

          <div className="mt-10">
            <Footer />
          </div>

        </div>
    </Transition>
  )
}

export default AZSwing