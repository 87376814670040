import React from 'react'
import { Nav } from '../components/Navbar'
import Marquee from "react-fast-marquee";
import Footer from '../components/Footer';
import MP4s from '../sliding dooor.mp4';
import SS04Gif from '../ss04gif.gif';
import Transition from '../Transition';
import { Link } from 'react-router-dom';

function Showers() {
  return (
    <Transition>
      <div className="overflow-hidden">
          <Nav />
      
          <section className="flex flex-col justify-center items-center relative w-screen h-[60vh] shadow-lg">
            <div className="z-10 ">
                <h3 className="text-6xl uppercase text-white font-bold text-center drop-shadow-lg filter">Glass Showers</h3>
            </div>

            {/* <div className='w-screen h-full bg-black absolute'></div> */}
            <img src="https://i.imgur.com/K1rvAPz.jpg" className="select-none w-screen h-full object-cover brightness-[.4] absolute top-0 left-0" />
          </section> 

          <div className="py-2 bg-black text-white">
            <Marquee className="overflow-hidden" gradient={false}>
              <h4 className="font-serif font-bold text-4xl mx-2">AZUPIN GLASS SHOWERS</h4>
              <h4 className="font-serif text-4xl mx-2">FAST SERVICE</h4>
              <h4 className="font-serif font-bold text-4xl mx-2">快速服务</h4>
              <h4 className="font-serif text-4xl mx-2">PROFESSIONAL SERVICE</h4>
              <h4 className="font-serif font-bold text-4xl mx-2">专业的服务</h4>
              <h4 className="font-serif text-4xl mx-2">RESPONSIVE TEAM</h4>
              <h4 className="font-serif font-bold text-4xl mx-2">响应团队</h4>
              <h4 className="font-serif font-bold text-4xl mx-2">FAST SERVICE</h4>
            </Marquee>
          </div>

          <section className="mx-auto lg:w-[80vw] w-full mt-10 flex flex-col justify-center items-center">
            <div className="w-full">
              <h4 className="font-bold text-3xl font-serif text-center">Choose from our:</h4>
              <div className="lg:grid-cols-3 gap-4 grid mx-auto justify-center items-center mt-10 lg:p-0 px-10">

                <Link to="/showers/AZ-Sliding">
                  <div className="flex flex-col justify-center items-center p-6 shadow-lg border border-blue-300 shadow-blue-300 hover:-translate-y-2 duration-300 cursor-pointer">
                    <img src="https://i.imgur.com/Ogtu7R8.png" className="h-60 object-fit" />
                    <h4 className="font-bold text-2xl text-blue-500">AZ-SLIDING</h4>
                    <p className="text-center mt-2">Sliding Shower Door to save space and give your bathroom a luxurious look <span className='font-semibold'>FRAMELESS!</span></p>
                  </div>
                </Link>
                <Link to="/showers/AZ-Swing">
                  <div className="flex flex-col justify-center items-center p-6 shadow-lg border shadow-blue-300 border-blue-300 hover:-translate-y-2 duration-300 cursor-pointer">
                    <img src="https://i.imgur.com/LAjgh7d.png" className="h-60 object-fit" />
                    <h4 className="font-bold text-2xl text-blue-500">AZ-SWING</h4>
                    <p className="text-center mt-2">Paired with low-profile to ensure your bathroom looks completely <span className='font-semibold'>FRAMELESS!</span></p>
                  </div>
                </Link>
                <div className="flex flex-col justify-center items-center p-6 shadow-lg border border-blue-300 shadow-blue-300 hover:-translate-y-2 duration-300 cursor-pointer">
                  <img src="https://i.imgur.com/kJK5xB9.png" className="h-60 object-fit" />
                  <h4 className="font-bold text-2xl text-blue-500">AZ-CUSTOM</h4>
                  <p className="text-center mt-2">Have something in mind and not here? We do everything related to glass! <span className='font-semibold'>GORGEOUS!</span></p>
                </div>

              </div>
            </div>
          </section>

          <section className="mx-auto lg:w-[80vw] w-full my-16 flex flex-col justify-center items-center">

            <h4 className="text-3xl font-semibold font-serif text-center">Why us?</h4>

            <div className="flex justify-center items-center mt-10 w-full lg:flex-row flex-col">
              <div className="flex justify-center items-center flex-1 overflow-hidden">
                  {/* <video autoPlay loop muted className="outline-none">
                      <source src={MP4s} type="video/mp4" />
                  </video> */}
                  <img src={SS04Gif} />
              </div>
              <div className="flex flex-col p-4 flex-1 lg:mt-0 mt-4">
                <h4 className="font-bold text-2xl text-center">3D Mock-Up Preview</h4>
                <p className="lg:w-96 mx-auto text-center mt-4">
                  Stop paying for something that you won't know the result of. With our 3D mock-ups previews you can rest assured that the project will be how
                  it has been ilustrated and designed. 
                </p>
              </div>
            </div>

            <div className="flex justify-center items-center mt-10 w-full lg:flex-row flex-col">
              <div className="flex flex-col p-4 flex-1 lg:mt-0 mt-4 lg:order-1 order-2">
                <h4 className="font-bold text-2xl text-center">Fast Service</h4>
                <p className="lg:w-96 mx-auto text-center mt-4">
                  Everything scheduled and planned from the day of the agreement. All according to the planning and if inconvinences happen, we will try our
                  best to make it done as soon as possible.
                </p>
              </div>
              <div className="flex justify-center items-center flex-1 lg:order-2 order-1">
                <img src="https://i.imgur.com/opzGZwj.png" />
              </div>
            </div>

          </section>

          <Footer />
      </div>
    </Transition>
  )
}

export default Showers