import bgVideo from '../bg.mp4';
import { Nav } from '../components/Navbar';
import Marquee from "react-fast-marquee";
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import Transition from '../Transition';
import { Slide } from 'react-awesome-reveal';

function MainPage() {
  return (
    <Transition>
      <div className="overflow-hidden">
          
        <Nav />

        <section className="flex flex-col justify-center items-center relative w-screen h-screen">

          <div className="z-10 ">
            <div className="overflow-hidden">
              <Slide triggerOnce delay={300} direction="up">
                <h3 className="text-5xl text-white text-center drop-shadow-lg filter">Where <span className="font-bold">Quality</span> and <span className="font-bold">Service</span> shines.<br/> Commercial and Residential.</h3>
              </Slide>
            </div>
            <div className="overflow-hidden">
              <Slide triggerOnce delay={500} direction="up">
               <p className="text-center text-white font-mono font-semibold">Take your construction to the next level.</p>
              </Slide>
            </div>
            <div className="overflow-hidden">
              <Slide triggerOnce delay={800} direction="up">
                <div className="flex justify-center items-center lg:flex-row flex-col mt-2">
                    <button className="text-white font-bold text-xl px-4 py-2 border-white border shadow-lg hover:text-black hover:bg-white duration-500 ease-in-out backdrop-blur">Learn More</button>
                </div>
              </Slide>
            </div>
          </div>

          <video autoPlay loop muted className="w-screen h-screen object-cover brightness-[.4] absolute top-0 left-0">
              <source src={bgVideo} type="video/mp4" />
          </video>
        </section>

        <section>
          <div className="lg:w-[80vw] w-full bg-white shadow-lg border p-6 transform -translate-y-10 mx-auto rounded-lg flex flex-col items-center justify-center py-20">
              <div className="overflow-hidden">
                <Slide direction='up' delay={300} triggerOnce>
                  <h3 className='overflow-hidden text-center text-4xl'>Best time to start is <span className="font-black">now</span>!</h3>
                </Slide>
              </div>
              <div className="overflow-hidden">
                <Slide direction='up' delay={500} triggerOnce>
                  <p className="text-center">What are you looking to do?  </p>
                </Slide>
              </div>

            <div className="flex lg:flex-row flex-col justify-evenly items-center mt-8 w-full">

              <Slide direction='up' duration={1000} triggerOnce>
                <Link to="/stairs">
                  <div className="lg:my-0 my-4 border shadow-lg p-4 flex justify-center items-center flex-col hover:-translate-y-2 duration-300 cursor-pointer hover:border-black rounded-lg">
                    <img className="w-60 h-60 object-cover" src="https://i.imgur.com/8rGxDvD.png" />
                    <p className="font-semibold text-lg mt-2">Stairs</p>
                  </div>
                </Link>
              </Slide>
              <Slide delay={100} direction='up' duration={1000} triggerOnce>
                <Link to="/railings">
                  <div className="lg:my-0 my-4 border shadow-lg p-4 flex justify-center items-center flex-col hover:-translate-y-2 duration-300 cursor-pointer hover:border-black rounded-lg">
                    <img className="w-60 h-60 object-cover" src="https://i.imgur.com/TEql6Gs.jpg" />
                    <p className="font-semibold text-lg mt-2">Railings</p>
                  </div>
                </Link>
              </Slide>
              <Slide delay={300} direction='up' duration={1000} triggerOnce>
                <Link to="/showers">
                  <div className="lg:my-0 my-4 border shadow-lg p-4 flex justify-center items-center flex-col hover:-translate-y-2 duration-300 cursor-pointer hover:border-black rounded-lg">
                    <img className="w-60 h-60 object-cover" src="https://i.imgur.com/Ogtu7R8.png" />
                    <p className="font-semibold text-lg mt-2">Showers</p>
                  </div>
                </Link>
              </Slide>
              <Slide delay={500} direction='up' duration={1000} triggerOnce>
                <div className="lg:my-0 my-4 border shadow-lg p-4 flex justify-center items-center flex-col hover:-translate-y-2 duration-300 cursor-pointer hover:border-black rounded-lg">
                  <img className="w-60 h-60 object-cover" src="https://i.imgur.com/q0FIhC4.png" />
                  <p className="font-semibold text-lg mt-2">Custom</p>
                </div>
              </Slide>

            </div>
            
          </div>
        </section>

        <section className="my-20">
          <div className="w-full flex flex-col">
            <h4 className="font-serif text-6xl font-semibold text-center">Gallery</h4>
            <div className="lg:w-28 h-2 mt-1 mb-4 bg-black mx-auto"></div>

            <Marquee gradient={false}>
              <div className="h-96 shadow-lg border brightness-[.6]">
                <img src="https://i.imgur.com/NFAhuuW.jpg" className="w-full h-full object-cover" />
              </div>
              <div className="h-96 shadow-lg border brightness-[.6]">
                <img src="https://i.imgur.com/UOhDT6D.jpg" className="w-full h-full object-cover" />
              </div>
              <div className="h-96 shadow-lg border brightness-[.6]">
                <img src="https://i.imgur.com/YjoJkOh.jpg" className="w-full h-full object-cover" />
              </div>
              <div className="h-96 shadow-lg border brightness-[.6]">
                <img src="https://i.imgur.com/OX6KL7V.jpg" className="w-full h-full object-cover" />
              </div>
              <div className="h-96 shadow-lg border brightness-[.6]">
                <img src="https://i.imgur.com/JjjtCvz.jpg" className="w-full h-full object-cover" />
              </div>
              <div className="h-96 shadow-lg border brightness-[.6]">
                <img src="https://i.imgur.com/gqHmZRo.jpg" className="w-full h-full object-cover" />
              </div>
              <div className="h-96 shadow-lg border brightness-[.6]">
                <img src="https://i.imgur.com/sbyDTAU.jpg" className="w-full h-full object-cover" />
              </div>
              <div className="h-96 shadow-lg border brightness-[.6]">
                <img src="https://i.imgur.com/PDaoKhS.jpg" className="w-full h-full object-cover" />
              </div>
              <div className="h-96 shadow-lg border brightness-[.6]">
                <img src="https://i.imgur.com/TRlnYxm.jpg" className="w-full h-full object-cover" />
              </div>
              <div className="h-96 shadow-lg border brightness-[.6]">
                <img src="https://i.imgur.com/hKciSjY.jpg" className="w-full h-full object-cover" />
              </div>
              <div className="h-96 shadow-lg border brightness-[.6]">
                <img src="https://i.imgur.com/onMxl6w.jpg" className="w-full h-full object-cover" />
              </div>
            </Marquee>

            <a className="mx-auto" target="_blank" href="https://www.instagram.com/azupin_glass/">
              <button className="px-4 py-2 border-2 border-black mx-auto mt-4 font-bold duration-500 hover:bg-black hover:text-white">
                More here...
              </button>

            </a>

          </div>
        </section>

        <section className="w-[80vw] mx-auto my-20">
          <div className="flex flex-col w-full">
            <h4 className="text-4xl font-serif font-semibold">Get the most cost-efficient information and service.</h4>
            <div className="h-2 lg:w-40 bg-black mt-1 mb-4"></div>

            <div className="flex lg:flex-row flex-col">
              <img className="h-96 object-cover flex-1 shadow-lg" src="https://images.unsplash.com/photo-1604709177225-055f99402ea3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8YmF0aHJvb218ZW58MHx8MHx8&w=1000&q=100" />
              <div className="bg-black flex-1 flex justify-center items-center flex-col px-6 py-10 lg:ml-1">
                <h3 className="text-2xl font-serif font-bold text-white text-center">Get the most out of your time.</h3>
                <div className="mt-2 px-4">
                  <p className="text-white">
                    Rest assured that accurate information are presented to you in the most professional manner. Including 3D Design Mock-Ups / Previews, to accurate estimates and ETA's for your project. We take pride into our service and quality before anything else.
                  </p>
                </div>
                <button className="border-white border-2 text-white font-semibold lg:px-10 px-4 py-2 mx-auto mt-4 duration-500 hover:text-black hover:bg-white text-sm">Contact</button>
              </div>
            </div>
          </div>
        </section>
      
        <Footer />

      </div>
    </Transition>
  );
}

export default MainPage;
