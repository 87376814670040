import React from 'react'
import Footer from '../components/Footer'
import { Nav } from '../components/Navbar'
import Transition from '../Transition'

function AZBlack() {
  return (
    <Transition>
        <div className="overflow-hidden">
            
          <Nav />

    
          <section className="flex flex-col justify-center items-center relative w-screen h-[50vh] shadow-lg">
            <div className="z-10 ">
                <h2 className="text-center text-white font-bold uppercase">Railings</h2>
                <h3 className="text-6xl uppercase text-white font-bold text-center drop-shadow-lg filter">AZ-Black</h3>
                <p className="text-white text-center lg:w-96 mx-auto">
                  Where the steel post and design shine!
                </p>
            </div>

            <img src="https://media.viewrail.com/wp-content/uploads/2019/03/18035543/172A9898-scaled.jpg" className="select-none w-screen h-full object-cover brightness-[.4] absolute top-0 left-0" />
          </section> 

          <section className="lg:w-[80vw] w-full mx-auto my-10">
            <p className="text-center mx-auto lg:w-3/4 p-4">
            Glass railings are a popular choice among homeowners and businesses alike. They provide an open, modern look that is both decorative and functional. Glass railings can be used both indoors and outdoors and come in various styles, such as frameless, semi-frameless, and framed. <br/><br/> These railings are usually made from tempered glass, which makes them strong and durable. The glass is also scratch-resistant, which helps keep them looking new for years to come. Glass railings provide a sleek and contemporary look, making them a great choice for any home or business.
            </p>
          </section>

          <section className="flex flex-col lg:flex-row justify-start items-center">
            <div className="relative flex-1">
              <div className="w-full top-0">
                <img className="w-full lg:h-screen object-cover" src="http://cdn.shopify.com/s/files/1/0317/5314/2409/products/stainless-steel-glass-clamps-for-stairs_9edeabd4-5338-42b4-ab21-fcd673860387_1024x.jpg?v=1602620814" />
              </div>
            </div>
            <div className="flex-1 lg:mt-0 mt-6">
              <div className="lg:p-10 flex justify-center items-center w-full">
                <div className="w-3/4">
                  <h4 className="text-blue-600 font-bold text-3xl">Quality Hardware</h4>
                  <p className="mt-4">Steel posts on glass railing is an ideal choice for homes and businesses that want to create an aesthetically pleasing look for their outdoor space. Steel posts are strong and durable, able to withstand the elements and increase the safety of a railing.  <br/><br/>  Steel posts can be used in combination with either tempered glass or laminated glass to create a stylish and secure railing system. Steel posts also provide a secure mounting point for the glass panels, ensuring that they remain in place and do not move over time. With a wide range of sizes, designs, and finishes, steel posts can be customized to match any home or business’s decor. Steel posts on glass railing are a great way to create a secure and stylish outdoor space.</p>
                
                  <div className="flex mt-4 justify-start items-start flex-wrap gap-4">
                    <div className="border shadow-lg rounded w-52">
                      <div className="p-10">
                        <img className="h-40 mx-auto object-cover" src="https://www.buyrailings.com/images/product/large/gs508-36_3.jpg" />
                      </div>
                      <div className="bg-gray-200 p-4">
                        <h4 className="font-bold">Glass Post</h4>
                      </div>
                    </div>
                    <div className="border shadow-lg rounded w-52">
                      <div className="p-10">
                        <img className="h-40 mx-auto object-cover" src="https://s.yimg.com/aah/yhst-172533759-9/glass-railing-square-clamp-15.jpg" />
                      </div>
                      <div className="bg-gray-200 p-4">
                        <h4 className="font-bold">Glass Clamp</h4>
                      </div>
                    </div>
                    <div className="border shadow-lg rounded w-52">
                      <div className="p-10">
                        <img className="h-40 mx-auto object-cover" src="http://cdn.shopify.com/s/files/1/0317/5314/2409/products/stainless-steel-square-cap-glass-railing-led_1024x.jpg?v=1608160361" />
                      </div>
                      <div className="bg-gray-200 p-4">
                        <h4 className="font-bold">Cap Rail Rounded & Squared</h4>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </section>

          <section className="flex flex-col lg:flex-row justify-start items-center mt-4">
            <div className="flex-1 lg:mt-0 mt-6 lg:order-1 order-2">
              <div className="lg:p-10 flex justify-center items-center w-full">
                <div className="w-3/4">
                  <h4 className="text-blue-600 font-bold text-3xl">Why Us</h4>
                  <p className="mt-4">
Choosing us to do your glass railings is a wise choice for many reasons. Our team has years of experience in glass railings installation, so you know you’ll be getting a quality product that is installed correctly. We also take pride in our customer service and strive to provide a pleasant experience for all of our clients. <br/> <br/> We use only the highest quality materials and have a wide selection of designs, including custom options, so you can find the perfect glass railing for your space. Our team understands that safety is a priority and all of our railings are OSHA compliant and meet all local building codes. Finally, our prices are competitive and we offer flexible payment plans to make your glass railing project more affordable.</p>
                
                  <button className="mt-4 px-4 py-2 bg-blue-500 hover:bg-blue-700 duration-300 font-bold text-white rounded shadow-lg">Get your FREE quote.</button>
                </div>

              </div>
            </div>
            <div className="relative flex-1 lg:order-2 order-1 lg:mt-0 mt-6">
              <div className="w-full top-0">
                <img className="w-full lg:h-screen object-cover" src="https://i.ebayimg.com/images/g/6FoAAOSwz2Bd5coQ/s-l1600.jpg" />
              </div>
            </div>
          </section>

          <div className="mt-10">
            <Footer />
          </div>

        </div>
    </Transition>
  )
}

export default AZBlack