import { motion, AnimatePresence } from 'framer-motion';
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const Transition = ({ children }) => {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  const variants = {
    out: {
      opacity: 0,
      y: 0,
      transition: {
        duration: 1,
        // ease: [0.87, 0.5, 0.13],
      },
    },
    in: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        // ease: [0.87, 0, 0.13, 1],
      },
    }
  };

  return (
    <div className="effect-1">
      <AnimatePresence
        // onExitComplete={}
        initial={true}
        mode="wait"
      >
        <motion.div
        //   key={}
          variants={variants}
          animate="in"
          initial="out"
          exit="out"
        >
          {children}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default Transition;