import React from 'react'
import Footer from '../components/Footer'
import { Nav } from '../components/Navbar'
import Transition from '../Transition'

function AZGold() {
  return (
    <Transition>
        <div className="overflow-hidden">
            
          <Nav />

    
          <section className="flex flex-col justify-center items-center relative w-screen h-[50vh] shadow-lg">
            <div className="z-10 ">
                <h2 className="text-center text-white font-bold uppercase">Railings</h2>
                <h3 className="text-6xl uppercase text-orange-500 font-bold text-center drop-shadow-lg filter">AZ-Gold</h3>
                <p className="text-white text-center lg:w-96 mx-auto">
                  Where the channels shine!
                </p>
            </div>

            <img src="https://www.stairsupplies.com/wp-content/uploads/2020/06/718A8257-1024x683.jpg" className="select-none w-screen h-full object-cover brightness-[.4] absolute top-0 left-0" />
          </section> 

          <section className="lg:w-[80vw] w-full mx-auto my-10">
            <p className="text-center mx-auto lg:w-3/4 p-4">
            Glass railings are a popular choice among homeowners and businesses alike. They provide an open, modern look that is both decorative and functional. Glass railings can be used both indoors and outdoors and come in various styles, such as frameless, semi-frameless, and framed. <br/><br/> These railings are usually made from tempered glass, which makes them strong and durable. The glass is also scratch-resistant, which helps keep them looking new for years to come. Glass railings provide a sleek and contemporary look, making them a great choice for any home or business.
            </p>
          </section>

          <section className="flex flex-col lg:flex-row justify-start items-center">
            <div className="relative flex-1">
              <div className="w-full top-0">
                <img className="w-full h-screen object-cover" src="https://wagnercompanies.com/wp-content/uploads/IMG_8375.jpeg" />
              </div>
            </div>
            <div className="lg:mt-0 mt-6 flex-1">
              <div className="lg:p-10 flex justify-center items-center w-full">
                <div className="w-3/4">
                  <h4 className="text-blue-600 font-bold text-3xl">Quality Hardware</h4>
                  <p className="mt-4">U-channels are a popular choice for glass railing systems due to their many advantages over clamps. U-channels are designed to fit the exact size of the glass panel, providing a secure fit and a smooth, finished look. <br/><br/> They also protect the edges of the glass from any damage, and are more aesthetically pleasing than clamps. U-channels also provide a better grip than clamps, making them more secure and less likely to loosen over time. Lastly, they are much easier to install and require less maintenance than clamps, making them the preferred option for many glass railing systems.</p>
                
                  <div className="flex mt-4 justify-start items-start flex-wrap gap-4">
                    <div className="border shadow-lg rounded w-52">
                      <div className="p-10">
                        <img className="h-40 mx-auto object-cover" src="https://ueeshop.ly200-cdn.com/u_file/UPAT/UPAT285/2204/products/17/9183b11de7.jpg?x-oss-process=image/resize,m_lfit,h_1000,w_1000" />
                      </div>
                      <div className="bg-gray-200 p-4">
                        <h4 className="font-bold">Glass Channel</h4>
                      </div>
                    </div>
                    <div className="border shadow-lg rounded w-52">
                      <div className="p-10">
                        <img className="h-40 mx-auto object-cover" src="https://fhc-usa.com/media/catalog/product/cache/85aed393dcaa93b40d69c099baa614b2/s/c/sc1wbs-2w.jpg" />
                      </div>
                      <div className="bg-gray-200 p-4">
                        <h4 className="font-bold">Glass Wall Clamp</h4>
                      </div>
                    </div>
                    <div className="border shadow-lg rounded w-52">
                      <div className="p-10">
                        <img className="h-40 mx-auto object-cover" src="https://fhc-usa.com/media/catalog/product/cache/a0e7f9cdeb7c54a44621219be8416acc/s/c/sc1803bs-2w_1.jpg" />
                      </div>
                      <div className="bg-gray-200 p-4">
                        <h4 className="font-bold">Glass Wall Clamp</h4>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </section>

          <section className="flex flex-col lg:flex-row justify-start items-center mt-4">
            <div className="flex-1 lg:order-1 order-2 lg:mt-0 mt-6">
              <div className="lg:p-10 flex justify-center items-center w-full">
                <div className="w-3/4">
                  <h4 className="text-blue-600 font-bold text-3xl">Why Us</h4>
                  <p className="mt-4">
Choosing us to do your glass railings is a wise choice for many reasons. Our team has years of experience in glass railings installation, so you know you’ll be getting a quality product that is installed correctly. We also take pride in our customer service and strive to provide a pleasant experience for all of our clients. <br/> <br/> We use only the highest quality materials and have a wide selection of designs, including custom options, so you can find the perfect glass railing for your space. Our team understands that safety is a priority and all of our railings are OSHA compliant and meet all local building codes. Finally, our prices are competitive and we offer flexible payment plans to make your glass railing project more affordable.</p>
                
                  <button className="mt-4 px-4 py-2 bg-blue-500 hover:bg-blue-700 duration-300 font-bold text-white rounded shadow-lg">Get your FREE quote.</button>
                </div>

              </div>
            </div>
            <div className="relative flex-1 lg:order-2 order-1 lg:mt-0 mt-6">
              <div className="w-full top-0">
                <img className="w-full h-screen object-cover" src="https://nebula.wsimg.com/eccbcd896e5c95d0a2172f9397b12b8d?AccessKeyId=AE0F5279EA2BFA9DA709&disposition=0&alloworigin=1" />
              </div>
            </div>
          </section>

          <div className="mt-10">
            <Footer />
          </div>

        </div>
    </Transition>
  )
}

export default AZGold