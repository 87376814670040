import { useState } from 'react'
import { useScrollIndicator } from 'react-use-scroll-indicator';
import { Link } from 'react-router-dom';
import { Fade, Slide } from 'react-awesome-reveal';

export const Nav = () => {

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [hoverItem, setHoverItem] = useState("");
    const [state] = useScrollIndicator();

    return (
      <div class={`${state.value >= 3 ? "bg-white text-black shadow-lg" : "bg-transparent text-white"} duration-300 fixed w-full top-0 left-0 py-5 mx-auto px-8 z-20`}>
        <div class="relative grid items-center grid-cols-2 lg:grid-cols-3">
          <ul class="items-center hidden space-x-8 lg:flex z-10">
            <li>
              <a
                onClick={() => setHoverItem(hoverItem ? "" : "About")}
                aria-label="About Us"
                title="About Us"
                class="font-medium cursor-pointer tracking-wide transition-colors duration-200 hover:text-deep-purple-accent-400"
              >
                About Us
              </a>
            </li>
            <li>
              <a
                onClick={() => setHoverItem(hoverItem ? "" : "Services")}
                // onMouseLeave={() => setHoverItem()}
                aria-label="Services"
                title="Services"
                class="cursor-pointer font-medium tracking-wide transition-colors duration-200 hover:text-deep-purple-accent-400"
              >
                Services
              </a>
            </li>
          </ul>
          
          <Link to="/">
            <div className="w-60 lg:mx-auto z-20">
              <img src="https://i.imgur.com/1D5hQqZ.png" className="z-20" />
            </div>
          </Link>
          <ul class="items-center hidden ml-auto space-x-8 lg:flex z-10">
            <li>
              <Link to="/contact">
                <button className={`${state.value >= 3 ? "border border-black text-black" : "border-white border"} rounded-lg shadow-lg px-4 py-2 hover:bg-black hover:text-white hover:border-black duration-300 `}>Request a quote</button>
              </Link>
            </li>
          </ul>
          {
              hoverItem === "Services" ?
              <Fade duration={300}>
                <div className="p-10 duration-300 h-auto w-screen fixed top-[5.5rem] left-0 bg-white flex justify-start items-center border-y-2 shadow-lg">
                  <div className="flex justify-evenly items-center mt-8 w-full">

                    <div className="overflow-hidden">
                      <Slide duration={500} triggerOnce direction='up'>
                        <Link to="/stairs">
                          <div className="border shadow-lg p-4 flex justify-center items-center flex-col hover:-translate-y-2 duration-300 cursor-pointer hover:border-black rounded-lg">
                            <img className="w-60 h-60 object-cover" src="https://i.imgur.com/8rGxDvD.png" />
                            <p className="font-semibold text-lg mt-2 text-black">Stairs</p>
                          </div>
                        </Link>
                      </Slide>
                    </div>

                    <div className="overflow-hidden">
                      <Slide duration={700} triggerOnce direction='up'>
                        <Link to="/railings">
                          <div className="border shadow-lg p-4 flex justify-center items-center flex-col hover:-translate-y-2 duration-300 cursor-pointer hover:border-black rounded-lg">
                            <img className="w-60 h-60 object-cover" src="https://i.imgur.com/TEql6Gs.jpg" />
                            <p className="font-semibold text-lg mt-2 text-black">Railings</p>
                          </div>
                        </Link>
                      </Slide>
                    </div>

                    <div className="overflow-hidden">
                      <Slide duration={900} triggerOnce direction='up'>
                        <Link to="/showers">
                          <div className="border shadow-lg p-4 flex justify-center items-center flex-col hover:-translate-y-2 duration-300 cursor-pointer hover:border-black rounded-lg">
                            <img className="w-60 h-60 object-cover" src="https://i.imgur.com/Ogtu7R8.png" />
                            <p className="font-semibold text-lg mt-2 text-black">Showers</p>
                          </div>
                        </Link>
                      </Slide>
                    </div>

                    <div className="overflow-hidden">
                      <Slide duration={1100} triggerOnce direction='up'>
                        <div className="border shadow-lg p-4 flex justify-center items-center flex-col hover:-translate-y-2 duration-300 cursor-pointer hover:border-black rounded-lg">
                          <img className="w-60 h-60 object-cover" src="https://i.imgur.com/q0FIhC4.png" />
                          <p className="font-semibold text-lg mt-2 text-black">Custom</p>
                        </div>
                      </Slide>
                    </div>

                  </div>
                </div>
              </Fade>
              :
              hoverItem === "About"
              ?
              <Fade duration={300}>
                <div className="p-10 duration-300 h-auto w-screen fixed top-[5.5rem] left-0 bg-white flex justify-start items-center border-y-2 shadow-lg">
                  <div className="flex justify-between items-start w-full">

                    <div className="w-96 flex flex-col justify-start items-start text-black">
                      <h4 className="text-lg font-bold">About Us</h4>
                      <p className="text-sm text-gray-800">
                        Specialized in design, manufacturer, and distribution. We offer our clients a wide selection of shower doors, glass railing, glass stairs and mirrors. 
                      </p>
                      <p className="mt-4 text-sm text-gray-800">
                        A highly-efficient and well-organized facility with a design team of experience and dedication is ready to offer solutions of your shower projects, whether standard or custom-made.
                      </p>
                    </div>
                    <div className="w-96 flex flex-col justify-start items-start text-black">
                      <h4 className="text-lg font-bold">Contact Us</h4>
                      <p className="text-sm text-gray-800">
                        Email: <a href="mailto:info@azupin.glass" className="text-blue-500 underline">info@azupin.glass</a>
                      </p>
                      <p className="text-sm text-gray-800">
                        Phone: <a href="tel:+1 909-276-7014" className="text-blue-500 underline">+1 909-276-7014</a>
                      </p>
                      <p className="text-sm text-gray-800 mt-4">
                        Location: 19295 E Walnut Drive N. City Of Industry, California, 91748
                      </p>
                      
                    </div>
                    <div className="w-96 flex flex-col justify-start items-start text-black">
                      <h4 className="text-lg font-bold">Location</h4>
                      <a href="https://goo.gl/maps/XYHsyxZQhXYsKMzN9">
                        <img src="https://i.imgur.com/vu9rduh.png" className="object-cover w-full h-36" />
                      </a>
                    </div>

                  </div>
                </div>
              </Fade>
              :
              ""
            }
          <div class="ml-auto lg:hidden">
              <button
                aria-label="Open Menu"
                title="Open Menu"
                class="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50"
                onClick={() => setIsMenuOpen(true)}
              >
                <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                  />
                  <path
                    fill="currentColor"
                    d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                  />
                  <path
                    fill="currentColor"
                    d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                  />
                </svg>
              </button>
            {isMenuOpen && (
              <div class="absolute top-0 left-0 w-full">
                <div class="p-5 bg-white border rounded shadow-sm">
                  <div class="flex items-center justify-between mb-4">
                    <div>
                      <div className="w-40 lg:mx-auto">
                        <img src="https://i.imgur.com/1D5hQqZ.png" />
                      </div>
                    </div>
                    <div>
                      <button
                        aria-label="Close Menu"
                        title="Close Menu"
                        class="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <nav>
                    <ul class="space-y-4">
                      <li>
                        <a
                          href="/railings"
                          aria-label="Our product"
                          title="Our product"
                          class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                        >
                          Railings
                        </a>
                      </li>
                      <li>
                        <a
                          href="/showers"
                          aria-label="Our product"
                          title="Our product"
                          class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                        >
                          Showers
                        </a>
                      </li>
                      <li>
                        <a
                          href="/stairs"
                          aria-label="Product pricing"
                          title="Product pricing"
                          class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                        >
                          Stairs
                        </a>
                      </li>
                      <li>
                        <a
                          href="/contact"
                          class="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-purple-400 hover:bg-purple-700 focus:shadow-outline focus:outline-none"
                          aria-label="Contact"
                          title="Contact"
                        >
                          Contact / Quote
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };