import './index.css'

import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { AnimatePresence } from "framer-motion";


import MainPage from './pages/MainPage';
import Stairs from './pages/Stairs';
import Railings from './pages/Railings';
import Showers from './pages/Showers';
import AZSilver from './pages/AZ-Silver';
import AZGold from './pages/AZ-Gold';
import AZBlack from './pages/AZ-Black';
import AZSilverS from './pages/AZ-SilverS';
import AZGoldS from './pages/AZ-GoldS';
import AZBlackS from './pages/AZ-BlackS';
import AZSliding from './pages/AZ-Sliding';
import AZSwing from './pages/AZ-Swing';

import ScrollToTop from './ScrollToTop';
import Contact from './pages/Contact';

function App() {
  return (
      <AnimatePresence mode="wait">
          <Router>
            <ScrollToTop />
            <Routes>
              <Route exact path="/" element={<MainPage />} />
              <Route exact path="/stairs" element={<Stairs />} />
              <Route exact path="/railings" element={<Railings />} />
              <Route exact path="/showers" element={<Showers />} />
              <Route exact path="/railings/AZ-Silver" element={<AZSilver />} />
              <Route exact path="/railings/AZ-Gold" element={<AZGold />} />
              <Route exact path="/railings/AZ-Black" element={<AZBlack />} />
              <Route exact path="/stairs/AZ-Silver" element={<AZSilverS />} />
              <Route exact path="/stairs/AZ-Gold" element={<AZGoldS />} />
              <Route exact path="/stairs/AZ-Black" element={<AZBlackS />} />
              <Route exact path="/showers/AZ-Sliding" element={<AZSliding />} />
              <Route exact path="/showers/AZ-Swing" element={<AZSwing /> } />
              <Route exact path="/contact" element={<Contact />} />
            </Routes>
          </Router>
      </AnimatePresence>
  );
}

export default App;
